<template>
	<div class="combomeal-page">
		<el-form :inline="true">
			<el-form-item :label="$t('seller.tableHead.packageType') + '：'">
				<el-select v-model="pageQuery.type" style="width: 120px;">
					<el-option v-for="(item, index) in typeOption" :key="index" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item :label="$t('seller.tableHead.packageStatus') + '：'">
				<el-select v-model="pageQuery.status" style="width: 120px;">
					<el-option v-for="(item, index) in statusOption" :key="index" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item :label="$t('seller.tableHead.packageName') + '：'">
				<el-input v-model="pageQuery.name" clearable>
				</el-input>
			</el-form-item>
			<el-form-item :label="$t('seller.tableHead.packageId') + '：'">
				<el-input v-model="pageQuery.collocation_treasure_id" clearable>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="handleSearch">{{ $t('seller.actions.search') }}</el-button>
				<el-button @click="handleSearchClear">{{ $t('seller.actions.reset') }}</el-button>
			</el-form-item>
		</el-form>
		<el-table v-loading="listLoading" :data="pageList" class="seller-table">
			<el-table-column :label="$t('seller.tableHead.packageMainPic')" min-width="100px">
				<template slot-scope="scope">
					<img :src="scope.row.img_urls.split(',')[0]" width="60px" height="60px" alt="">
				</template>
			</el-table-column>
			<el-table-column :label="$t('seller.tableHead.packageNameId')" prop="status_desc">
				<template slot-scope="scope">
					<p>{{ scope.row.name }}</p>
					<p>{{ $t('seller.tableHead.packageNameId') + '：' }}{{ scope.row.collocation_treasure_id }}</p>
				</template>
			</el-table-column>
			<el-table-column :label="$t('seller.tableHead.packageType')">
				<template slot-scope="scope">{{ scope.row.type | typeFilter($t('seller.filters.packageType')) }}</template>
			</el-table-column>
			<el-table-column :label="$t('seller.tableHead.packageStatus')">
				<template slot-scope="scope">{{ scope.row.status | typeFilter($t('seller.filters.packageStatus')) }}</template>
			</el-table-column>
			<el-table-column :label="$t('seller.tableHead.couponInfo')">
				<template slot-scope="scope">{{ scope.row.dis_sel | typeFilter($t('seller.filters.packageCoupon')) }}</template>
			</el-table-column>
			<el-table-column :label="$t('seller.tableHead.couponTime')" prop="limit_give_num">
				<template slot-scope="scope">
					<template v-if="scope.row.dis_sel === 1">
						{{ scope.row.start_time | parseTime('{y}-{m}-{d}') }}
						<span>-</span>
						{{ scope.row.end_time | parseTime('{y}-{m}-{d}') }}
					</template>
					<template v-else>{{ $t('seller.tag.not') }}</template>
				</template>
			</el-table-column>
			<el-table-column :label="$t('seller.tableHead.couponStatus')" prop="give_num">
				<template slot-scope="scope">
					<template v-if="scope.row.dis_sel === 1">
						{{ scope.row.end_time > parseInt(Date.now() / 1000) ? $t('seller.combomeal.couponAfoot') : $t('seller.combomeal.couponEnd') }}
					</template>
					<template v-else>{{ $t('seller.tag.not') }}</template>
				</template>
			</el-table-column>
			<el-table-column :label="$t('seller.tableHead.handle')">
				<template slot-scope="scope">
					<el-button type="text"
						@click="$router.push({ name: 'combomealCreate', params: { id: scope.row.collocation_treasure_id } })">{{ $t('seller.actions.edit') }}</el-button>
					<el-button v-if="scope.row.status === 1" type="text" @click="handleRestartShow(scope)">{{ $t('seller.actions.recovery') }}</el-button>
					<el-button v-else type="text" @click="handleSuspend(scope)">{{ $t('seller.actions.suspend') }}</el-button>
					<el-button type="text" @click="handleDelete(scope)">{{ $t('seller.actions.delete') }}</el-button>
				</template>
			</el-table-column>
		</el-table>
		<pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageCurr" :limit.sync="pageSize"
			@pagination="getList"></pagination>
	</div>
</template>

<script>
import Pagination from '@/components/seller/pagination'
import { fetchCombomealList, suspendCombomeal, restartCombomeal, deleteCombomeal } from '@/api/seller/market'
const defaultQuery = {
	type: '',
	status: '',
	name: '',
	collocation_treasure_id: ''
}
export default {
	components: { Pagination },
	data() {
		return {
			typeOption: this.$t('seller.options.combomealType'),
			statusOption: this.$t('seller.options.combomealStatus'),
			pageQuery: Object.assign({}, defaultQuery),
			listLoading: false,
			pageList: [],
			pageCurr: 1,
			pageSize: 10,
			pageTotal: 0
		}
	},
	created() {
		this.getList()
	},
	methods: {
		getList() {
			this.listLoading = true
			let param = {
				...this.pageQuery,
				page_index: (this.pageCurr - 1) * this.pageSize,
				page_size: this.pageSize
			}
			fetchCombomealList(param).then(response => {
				const { data } = response
				this.pageList = data.list
				this.pageTotal = parseInt(data.count)
				this.listLoading = false
			})
		},
		handleSearch() {
			this.pageCurr = 1
			this.getList()
		},
		handleSearchClear() {
			this.pageQuery = Object.assign({}, defaultQuery)
			this.pageCurr = 1
			this.getList()
		},
		handleSuspend({ row }) {
			this.$confirm(this.$t('seller.confirm.suspendText'), this.$t('seller.confirm.title'), {
				confirmButtonText: this.$t('seller.actions.confirm'),
				cancelButtonText: this.$t('seller.actions.cancel'),
				type: 'warning'
			}).then(() => {
				suspendCombomeal({ collocation_treasure_id: row.collocation_treasure_id }).then(() => {
					this.getList()
				})
			}).catch(() => { })
		},
		handleRestartShow({ row }) {
			this.$confirm(this.$t('seller.confirm.restartText'), this.$t('seller.confirm.title'), {
				confirmButtonText: this.$t('seller.actions.confirm'),
				cancelButtonText: this.$t('seller.actions.cancel'),
				type: 'warning'
			}).then(() => {
				restartCombomeal({ collocation_treasure_id: row.collocation_treasure_id }).then(() => {
					this.getList()
				})
			}).catch(() => { })
		},
		handleDelete({ row }) {
			this.$confirm(this.$t('seller.confirm.deleteText5'), this.$t('seller.confirm.title'), {
				confirmButtonText: this.$t('seller.actions.confirm'),
				cancelButtonText: this.$t('seller.actions.cancel'),
				type: 'warning'
			}).then(() => {
				deleteCombomeal({ collocation_treasure_id: row.collocation_treasure_id }).then(() => {
					this.getList()
				})
			}).catch(() => { })
		}
	}
}
</script>

<style lang="scss" scoped>
.combomeal-page {
	.action-link {
		a {
			color: $--seller-primary;
			margin-right: 10px;
			display: inline-block;
		}
	}
}
</style>