<template>
	<el-scrollbar class="seller-scrollbar">
		<el-card class="seller-card">
			<el-breadcrumb slot="header" separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">{{$t('seller.breadcrumb.seller')}}</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ name: 'marketingtools' }">{{$t('seller.breadcrumb.marketPlatform')}}
				</el-breadcrumb-item>
				<el-breadcrumb-item>{{$t('seller.breadcrumb.combomeal')}}</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="page-tip">
				<div class="pic"><img src="@/assets/images/seller/market-tps-006.png" alt=""></div>
				<div class="tip-main">
					<div class="name">{{$t('seller.combomeal.title')}}</div>
					<div class="text">{{$t('seller.combomeal.tips')}}</div>
					<ul class="desc">
						<li>{{$t('seller.combomeal.packageNumber')}}<span>{{ pageData.tt_num || '0/40' }}</span></li>
						<li>{{$t('seller.combomeal.packageGoods')}}<span>{{ pageData.g_num || 0 }}</span></li>
					</ul>
				</div>
			</div>
		</el-card>
		<el-card class="seller-card">
			<template slot="header">{{$t('seller.coupon.title')}}</template>
			<div class="page-tools">
				<router-link class="item" :to="{ name: 'combomealCreate' }">
					<img class="item-pic" src="@/assets/images/seller/market-tps-006.png">
					<div class="item-main">
						<div class="name">{{$t('seller.combomeal.title')}}</div>
						<div class="bottom">
							<el-button type="primary" size="mini">{{'+'+$t('seller.actions.createActivity')}}</el-button>
						</div>
					</div>
				</router-link>
			</div>
		</el-card>
		<el-card class="seller-card card-tabs">
			<el-tabs slot="header" v-model="activeTabs" @tab-click="handleClickTabs" class="seller-tabs">
				<el-tab-pane :label="$t('seller.combomeal.packageManage')" name="1">
				</el-tab-pane>
				<el-tab-pane :label="$t('seller.combomeal.packageGoodsManage')" name="2">
				</el-tab-pane>
				<el-tab-pane :label="$t('seller.combomeal.overview')" name="3">
				</el-tab-pane>
			</el-tabs>
			<act-list v-if="activeTabs === '1'" />
			<act-item v-if="activeTabs === '2'" />
			<act-data v-if="activeTabs ==='3'" />
		</el-card>
	</el-scrollbar>
</template>

<script>
import actList from './components/list'
import actItem from './components/item'
import actData from './components/data'
import { fetchCombomealData }  from '@/api/seller/market'
export default {
	components: { actList, actItem, actData },
	data() {
		return {
			activeTabs: '1',
			pageData: {}
		}
	},
	created() {
		this.getData()
	},
	methods: {
		getData() {
			fetchCombomealData().then(response => {
				this.pageData = response.data
			})
		},
		handleClickTabs(tab, event) {
		},
	}
}
</script>

<style lang="scss" scoped>
.seller-card {

	.page-tip {
		display: flex;
		background-color: #FFFFFF;
		margin-bottom: 10px;

		.pic {
			width: 118px;
			height: 118px;
			margin-right: 42px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.tip-main {
			flex: 1;
			width: 0;

			.name {
				color: #333333;
				font-size: 16px;
				margin-top: 10px;
			}

			.text {
				font-size: 12px;
				line-height: 18px;
				margin-top: 10px;
				margin-bottom: 20px;
			}
			.desc {
				li {
					color: #333333;
					display: inline-block;
					margin-right: 30px;
					span {
						margin: 0 5px;
						font-size: 22px;
						color: #b01100;
					}
				}
			}
		}
	}
	.page-tools {

		.item {
			width: 210px;
			display: inline-block;
			margin-right: 20px;
			margin-bottom: 20px;

			.item-pic {
				float: left;
				width: 80px;
				height: 80px;
				object-fit: cover;
			}

			.item-main {
				margin-left: 80px;
				padding: 2px 8px 0;

				.name {
					color: #666666;
					height: 22px;
					line-height: 22px;
					font-size: 16px;
				}

				.bottom {
					margin-top: 28px;
				}
			}
		}
	}
}
</style>
