<template>
	<div class="combomeal-page">
		<el-form :inline="true">
			<el-form-item :label="$t('seller.screen.goodsCate') + '：'">
				<el-select v-model="pageQuery.store_cate_id" placeholder="">
					<el-option :label="$t('seller.combomeal.allCate')" :value="0"></el-option>
					<el-option v-for="(item, index) in cateOptions" :key="index" :label="item.cate_name"
						:value="item.cate_id"></el-option>
					<el-option :label="$t('seller.combomeal.other')" :value="-1"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item :label="$t('seller.screen.goodsName') + '：'">
				<el-input v-model="pageQuery.goods_name" clearable>
				</el-input>
			</el-form-item>
			<el-form-item :label="$t('seller.screen.goodsId') + '：'">
				<el-input v-model="pageQuery.goods_ids" clearable>
				</el-input>
			</el-form-item>
			<el-form-item :label="$t('seller.screen.goodsCode') + '：'">
				<el-input v-model="pageQuery.goods_code" clearable>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="handleSearch">{{ $t('seller.actions.search') }}</el-button>
				<el-button @click="handleReset">{{ $t('sycm.actions.reset') }}</el-button>
			</el-form-item>
		</el-form>
		<div class="page-list">
			<table v-loading="listLoading" class="page-table" width="100%">
				<thead>
					<tr>
						<th>{{ $t('seller.tableHead.baby') }}</th>
						<th>{{ $t('seller.tableHead.packageNameId') }}</th>
						<th>{{ $t('seller.tableHead.packageType') }}</th>
						<th>{{ $t('seller.tableHead.packageStatus') }}</th>
						<th>{{ $t('seller.combomeal.number') }}</th>
						<th>{{ $t('seller.combomeal.unitPrice') }}</th>
						<th>{{ $t('seller.combomeal.price') }}</th>
						<th>{{ $t('seller.tableHead.goodsType') }}</th>
						<th>{{ $t('seller.tableHead.frontDisplay') }}</th>
						<th>{{ $t('seller.tableHead.handle') }}</th>
					</tr>
				</thead>
				<tbody v-for="item in pageList" :key="item.goos_id">
					<tr v-for="(child, index) in item.st_list" :key="index">
						<td :rowspan="item.st_list.length">
							<div class="goods-item">
								<div class="item-pic"><img :src="item.default_image" @error="goodsLoadError" alt=""></div>
								<div class="item-main">
									<a href="javascript:void(0);" class="name">{{ item.goods_name }}</a>
									<div class="text" v-if="item.goods_code">{{ $t('seller.combomeal.goodsCode') + '：' }}{{ item.goods_code }}</div>
									<div class="text">{{ $t('seller.tableHead.stock') + '：' }}{{ item.stock }}</div>
									<div class="text">{{ $t('seller.combomeal.babyStatus') + '：' }}{{ item.if_show | typeFilter($t('seller.filters.goodsType')) }}</div>
								</div>
							</div>
						</td>
						<td>
							<div>{{ child.name }}</div>
							<div>ID: {{ child.collocation_treasure_id }}</div>
						</td>
						<td align="center">{{ child.type | typeFilter($t('seller.filters.packageType')) }}</td>
						<td align="center">{{ child.status | typeFilter($t('seller.filters.packageStatus')) }}</td>
						<td align="center">{{ child.goods_info.num }}</td>
						<td align="center">￥{{ item.price }}</td>
						<td align="center">{{ onRangePrice(child.goods_info) }}</td>
						<td align="center">{{ child.goods_info.is_main | typeFilter($t('seller.filters.packageGoods')) }}</td>
						<td align="center">{{ $t('seller.combomeal.package') }}{{ index + 1 }}</td>
						<td>
							<div>
								<el-button type="text" @click="$router.push({ name: 'combomealCreate', params: { id: child.collocation_treasure_id } })">{{ $t('seller.actions.editPackge') }}</el-button>
							</div>
							<div v-if="child.goods_info.is_main !== 1">
								<el-button type="text" @click="handleWithdrawal(item.goods_id,child.collocation_treasure_id)">{{ $t('seller.actions.withdrawalPackage') }}</el-button>
							</div>
						</td>
					</tr>
				</tbody>
				<tbody v-if="!pageList.length > 0">
					<tr>
						<td colspan="10" align="center">{{ $t('seller.empty.nodata') }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageCurr" :limit.sync="pageSize"
			@pagination="getList"></pagination>
	</div>
</template>

<script>
import Pagination from '@/components/seller/pagination'
import { fetchTreasuresCategory, fetchCombomealItems, deleteCombomealItem } from '@/api/seller/market'
const defaultQuery = {
	store_cate_id: 0,
	goods_name: '',
	goods_id: '',
	goods_code: ''
}
export default {
	components: { Pagination },
	props: ['type'],
	data() {
		return {
			cateOptions: [],
			pageQuery: Object.assign({}, defaultQuery),
			listLoading: false,
			pageList: [],
			pageCurr: 1,
			pageSize: 10,
			pageTotal: 0
		}
	},
	created() {
		this.getData()
		this.getList()
	},
	methods: {
		goodsLoadError(e) {
			e.target.src = this.$imageErrorGoods;
		},
		async getData() {
			const { data } = await fetchTreasuresCategory()
			this.cateOptions = data.list
		},
		getList() {
			this.listLoading = true
			let params = {
				...this.pageQuery,
				page_index: (this.pageCurr - 1) * this.pageSize,
				page_size: this.pageSize
			}
			fetchCombomealItems(params).then(response => {
				const { data } = response
				this.pageList = data.list
				this.pageTotal = parseInt(data.count)
				this.listLoading = false
			})
		},
		handleSearch() {
			this.pageCurr = 1
			this.getList()
		},
		handleReset() {
			this.pageQuery = Object.assign({}, defaultQuery)
			this.pageCurr = 1
			this.getList()
		},
		onRangePrice(goods) {
			var text = '未设置'
			if (goods.goods_specs && goods.goods_specs.length > 0) {
				var min = 0
				var max = 0
				goods.goods_specs.forEach(item => {
					if (item.dis_price) {
						if (min > 0 || parseFloat(item.dis_price) < min) {
							min = parseFloat(item.dis_price)
						}
						if (max > 0 || parseFloat(item.dis_price) > max) {
							max = parseFloat(item.dis_price)
						}
					}
				})
				if (min !== max) {
					text = '￥' + min.toFixed(2) + '-' + max.toFixed(2)
				} else if (min > 0) {
					text = '￥' + min.toFixed(2)
				}

			} else {
				if (goods.dis_price && parseFloat(goods.dis_price) > 0) {
					text = '￥' + parseFloat(goods.dis_price).toFixed(2)
				}
			}
			return text
		},
		handleWithdrawal(goodsId,activityId) {
            this.$confirm(this.$t('seller.confirm.withdrawalPackage'), this.$t('seller.confirm.batchWithdrawalTitle'), {
                confirmButtonText: this.$t('seller.actions.confirm'),
                cancelButtonText: this.$t('seller.actions.cancel'),
                type: 'warning'
            }).then(() => {
				deleteCombomealItem({ goods_id:goodsId,collocation_treasure_id: activityId}).then(() => {
					this.getList()
				})
            }).catch(() => { })
		}
	}
}
</script>

<style lang="scss" scoped>
.combomeal-page {
	.page-table {
		th {
			background-color: $--seller-thead-background-1;
		}

		th,
		td {
			padding: 8px 16px;
			line-height: 24px;
			border: 1px solid #e6e7eb;
		}
	}

	.goods-item {
		display: flex;
		min-width: 240px;

		.item-pic {
			flex-shrink: 0;
			width: 68px;
			height: 68px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.item-main {
			flex: 1;
			width: 0;
			margin-left: 10px;
			font-size: 12px;
			line-height: 1.28571;

			.name {
				color: #333333;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.text {
				color: #999999;
			}
		}
	}
}
</style>